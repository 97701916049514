<template>
  <div>
    <v-overlay
      :value="
        statusRequest.value === 'loading-getHomeSettingBySite' ||
          productTypeStatusRequest.value === 'loading-getAllProductTypes'
      "
      absolute
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-form ref="homeSettingRef">
      <div class="d-flex home-setting-container">
        <sidebar-section
          v-model="activeTab"
          class="sidebar-wrapper"
        ></sidebar-section>

        <main-section
          :active-tab="activeTab"
          class="ml-4 w-full"
          @onUpdate="handleUpdate()"
        ></main-section>
      </div>
    </v-form>
  </div>
</template>

<script>
import MainSection from "./components/MainSection/index.vue";
import SidebarSection from "./components/SidebarSection.vue";
import { toFormData } from "@vt7/utils";
import { showModalAlertError } from "@/core/composables";
import MediaService from "@/core/service/media.service";

export default {
  components: {
    MainSection,
    SidebarSection
  },

  data() {
    return {
      activeTab: 0
    };
  },

  computed: {
    homeSetting() {
      return this.$store.getters["HOME_SETTING/homeSetting"];
    },

    productTypeStatusRequest() {
      return this.$store.getters["PRODUCT_TYPE/statusRequest"];
    },

    statusRequest() {
      return this.$store.getters["HOME_SETTING/statusRequest"];
    },

    uploadedThumbnail() {
      return this.$store.getters["PRODUCT_TYPE/uploadedThumbnail"];
    }
  },

  async created() {
    await this.$store.dispatch("HOME_SETTING/getHomeSettingBySite");
    await this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    await this.$store.dispatch("PROMOTION/getPromotions", {
      filter: {
        created_by: [],
        status: [2, 1],
        time_from: null,
        time_to: null,
        type: null
      },
      per_page: 10000
    });
  },

  methods: {
    async onUploadThumbnail() {
      await this.uploadThumbnail(
        toFormData({
          file: this.homeSetting.BANNER.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.homeSetting.BANNER.thumbnail = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh thumbnail",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    async onUploadCover() {
      await this.uploadThumbnail(
        toFormData({
          file: this.homeSetting.BANNER.cover
        })
      );

      // Set uploaded cover
      if (this.productTypeStatusRequest.value === "success-uploadThumbnail") {
        this.homeSetting.BANNER.cover = this.uploadedThumbnail;
      } else if (
        this.productTypeStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh cover",
          message: this.productTypeStatusRequest.message
        });

        return;
      }
    },

    async handleImageItems(items) {
      const _promises = items
        .filter(item => item.id && item.image)
        .map(async item => {
          if (typeof item.image === "string") return item;

          const { data } = await MediaService.uploadImage(
            toFormData({
              file: item.image,
              folder: "social/articles"
            })
          );

          return {
            ...item,
            image: data
          };
        });

      return await Promise.all(_promises);
    },

    async handleFlashSale() {
      const flashsale = this.homeSetting.FLASH_SALE;

      if (flashsale.frame_image instanceof File) {
        const { data } = await MediaService.uploadImage(
          toFormData({
            file: flashsale.frame_image,
            folder: "core/categories"
          })
        );

        this.homeSetting.FLASH_SALE.frame_image = data;
      }
    },

    async handleUpdate() {
      const isValid = this.$refs.homeSettingRef.validate();

      if (!isValid) return;

      // Check thumbnail changes
      if (this.homeSetting.BANNER.thumbnail instanceof File) {
        await this.onUploadThumbnail();
      }

      // Check cover changes
      if (this.homeSetting.BANNER.cover instanceof File) {
        await this.onUploadCover();
      }

      // Check cover mobile changes
      if (this.homeSetting.BANNER.cover_mobile instanceof File) {
        const { data } = await MediaService.uploadImage(
          toFormData({
            file: this.homeSetting.BANNER.cover_mobile,
            folder: "core/categories"
          })
        );

        this.homeSetting.BANNER.cover_mobile = data;
      }

      this.homeSetting.BANNER.ids = this.homeSetting.BANNER.items.map(
        item => item.id
      );

      await this.handleFlashSale();

      this.homeSetting.ARTICLE_FOLDER.items = await this.handleImageItems(
        this.homeSetting.ARTICLE_FOLDER.items
      );
      this.homeSetting.ARTICLE_FOLDER.ids = this.homeSetting.ARTICLE_FOLDER.items.map(
        item => item.id
      );
      this.homeSetting.FEEDBACK.items = await this.handleImageItems(
        this.homeSetting.FEEDBACK.items
      );

      await this.$store.dispatch(
        "HOME_SETTING/updateHomeSetting",
        this.homeSetting
      );

      if (this.statusRequest.value === "success-updateHomeSetting") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật thành công"
          }
        });
      } else if (this.statusRequest.value === "error-updateHomeSetting") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.updateHomeSetting.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      await this.$store.dispatch("PRODUCT_TYPE/uploadThumbnail", formData);
    }
  }
};
</script>

<style scoped>
.home-setting-container {
  height: calc(100vh - 143px);
}

.sidebar-wrapper {
  width: 280px;
  flex-shrink: 0;
}
</style>
